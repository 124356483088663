import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col, Button } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo
      title="404: Not found"
      pathname="/404"
      description="We couldn't find the page you were looking for."
    />
    <Container>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <section>
            <Row className="u-centerFlex">
              <Col md={{ size: 12 }}>
                <h1>Page not found</h1>
                <p>
                  Unfortunately we couldn’t find the page you were looking for.
                </p>

                <div style={{ marginTop: "40px" }}>
                  <Link to="/">
                    <Button color="secondary" size="md">
                      Back to home &rarr;
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </section>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
